import { useEffect, useState } from "react";
import Typewriter from 'typewriter-effect';

function ChibiContact(props: any) {
  const [state, setState] = useState({
    scale: '',
    typewriterKey: Date.now(),
  });

  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, scale: 'scale-up' }));
      setState((prevState) => ({
        ...prevState,
        typewriterKey: Date.now()
      }));
    }, 2000);
    updateType();
  }, []);

  function updateType() {
    setState((prevState) => ({
      ...prevState,
      typewriterKey: Date.now()
    }));
  }

  return (
    <div id="explorisDraw">
            <div
        className={`exploris ${
          state.scale === 'scale-up'
            ? 'visible'
            : 'invisible'
        }`}
      >
        <img
          src={process.env.PUBLIC_URL + '/devexploris.webp'}
          alt="devexploris"
          height={200}
          loading="lazy"
        />
        <div className={`shape ${state.scale}`}>
              <Typewriter
                key={state.typewriterKey}
                options={{ loop: false }}
                onInit={(typewriter) => {
                  typewriter.deleteAll().typeString(props.dialog).start().pauseFor(1500).deleteAll().start();
                }}
              />
        </div>
      </div>
    </div>
  );
}
  

export default ChibiContact;
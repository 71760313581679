import Tools  from '../components/Tools';
import AboutMe from "../components/AboutMe";
import Chibi from "../components/Chibi";
import Hello from "../components/Hello";
import Icons from "../components/Icons";
import Projects from "../components/Projects";
import { isLaptop, isMobile, isPc, isWide } from '../functions';
import { motion } from 'framer-motion';
import { useState } from 'react';
import DailyDevProfile from '../components/DailyDevProfile';
import Joytistics from '../components/Joytistics';

function Home() {

    const [show, setShow] = useState(false);

    return(
            <motion.main
            className="main__container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
        >
            <DailyDevProfile canBeDisplayed={() => setShow(false)} display={show} />
            <Icons canBeDisplayed={() => setShow(true)} />
            <Chibi deltaBegin={-10} deltaEnd={150} />
            <Hello />
            <Joytistics />
            <div className="limited">
                <AboutMe delta={isPc() ? 300 : isLaptop() ? 400 : isWide() ? 400 : isMobile() ? 200 : 200} />
                <Tools delta={isPc() ? 1300 : isLaptop() ? 1300 : isWide() ? 800 : isMobile() ? 1400 : 1750} />
                <Projects delta={isPc() ? 1600 : isLaptop() ? 1800 : isWide() ? 1200 : isMobile() ? 2300 : 2100} />
                <div className="space"></div>
            </div>
        </motion.main>
    );
}

export default Home;
function isWide() {
    return window.innerWidth >= 768 ? true : false;
}

function isMobile() {
    return window.innerWidth <= 500 ? true : false;
}

function isLittleMobile() {
    return window.innerWidth <= 330 ? true : false;
}

function isLaptop() {
    return window.innerWidth >= 996 && window.innerHeight >= 600 ? true : false;
}

function isPc() {
    return window.innerWidth >= 1920 && window.innerHeight >= 1080 ? true : false;
}

export {isWide, isMobile, isLittleMobile, isLaptop, isPc};
import { useEffect, useState } from "react";
import Typewriter from 'typewriter-effect';
import { useTranslation } from "react-i18next";

function ChibiProject(props: any) {

  const { i18n, t } = useTranslation();

  const [state, setState] = useState({
    scale: '',
    typewriterKey: Date.now(), // Ajoute une clé unique pour forcer le remontage
  });

  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, scale: 'scale-up' }));
    }, 1000);
    if(i18n.language === "fr") {
      setState((prevState) => ({ ...prevState, dialog: 'Un clic pour du contexte. Deux clics pour visiter.' }));
    } else {
      setState((prevState) => ({ ...prevState, dialog: 'One click for context. Two click to visit.' }));
    }
    updateType();
  }, [props.dialog]);

  function updateType() {
    setState((prevState) => ({
      ...prevState,
      typewriterKey: Date.now()
    }));
  }

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="explorisDraw">
      <div
        className={`exploris ${
          scrollY > props.deltaBegin &&
          scrollY < props.deltaBegin + props.deltaEnd
            ? 'visible'
            : 'invisible'
        }`}
      >
        <img
          src={process.env.PUBLIC_URL + '/devexploris.webp'}
          alt="devexploris"
          height={200}
          loading="lazy"
        />
        <div className={`shape ${state.scale}`}>
          {scrollY > props.deltaBegin &&
            scrollY < props.deltaBegin + props.deltaEnd && (
              <Typewriter
                key={state.typewriterKey}
                options={{ loop: false }}
                onInit={(typewriter) => {
                  typewriter.deleteAll().typeString(props.dialog === '' ? t('chibiSetProject') : props.dialog).start().pauseFor(1000).deleteAll().start();
                }}
              />
            )}
        </div>
      </div>
    </div>
  );
}
  

export default ChibiProject;
import { useState } from "react";
import { useTranslation } from "react-i18next";

function LanguageSelector() {
    const { i18n } = useTranslation();
    const [flag, setFlag] = useState(i18n.language === 'fr' ? 'french' : 'english');

    function handleLanguageChange() {
        let newLanguage = "";
    if(i18n.language === "fr") {
        newLanguage = "en";
        setFlag("english");
    } else {
        newLanguage = "fr";
        setFlag("french");
    }
    i18n.changeLanguage(newLanguage);
}

    return(
        <div className="iconBox" title="Choose your language">
            <img src={process.env.PUBLIC_URL + "/icons/" + flag + ".webp"} loading="lazy" alt="language" onClick={handleLanguageChange}/>
        </div>  
    );
}

export default LanguageSelector;
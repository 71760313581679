import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageSelector from "./LanguageSelector";

function Icons(props : any) {

    const { t } = useTranslation();
    const location = useLocation();

    function dailyDevAppear() {
      if(props.canBeDisplayed !== undefined) {
        props.canBeDisplayed();
      }
    }

    return(
      <div className="icons">
        {location.pathname === '/contact' &&
            <Link className="iconBox" to="/"><img src={process.env.PUBLIC_URL + "/icons/home.webp"} alt={t('home')}/></Link>
        }
        {location.pathname === '/' && 
            <Link className="iconBox" to="/contact" title={t('leaveMessage')}><img src={process.env.PUBLIC_URL + "/icons/contact.webp"} alt="contact"/></Link>
        }
        {location.pathname === '/legals-notices' &&
          <Link className="iconBox" to="/"><img src={process.env.PUBLIC_URL + "/icons/home.webp"} alt="home"/></Link>
        }
        {location.pathname === '/' && 
          <a href="#tools" title={t('toolbox')} className="iconBox">
              <img src={process.env.PUBLIC_URL + "/icons/tools.webp"} alt="tools"/>
          </a>
        }
        {location.pathname === '/' && 
          <a href="#projects" title={t('projects')} className="iconBox">
              <img src={process.env.PUBLIC_URL + "/icons/projects.webp"} alt="projects"/>
          </a> 
        }
        <a className="iconBox" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/julian-g-86851b29a" title={t('profile')}>
          <img src={process.env.PUBLIC_URL + "/icons/linkedin.webp"} alt="profile"/>
        </a>
        {location.pathname === '/' && 
        <a className="iconBox" href="#" title={t('dailydev')} onClick={dailyDevAppear}>
          <img src={process.env.PUBLIC_URL + "/icons/dailydev.webp"} alt="dailydev"/>
        </a>
        }
        {location.pathname === '/' && 
          <Link to="/legals-notices" title={t('legalsNotices')} className="iconBox">
            <img src={process.env.PUBLIC_URL + "/icons/legals.webp"} alt="legals"/>
          </Link>
        }
        {location.pathname === '/' && 
          <LanguageSelector />
        }
      </div>
    );

}

export default Icons;
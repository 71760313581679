import { useEffect } from "react";

function DailyDevProfile(props : any) {

    useEffect(() => {
        if(props.display) {
            document.body.classList.add('overflow-none');
        } else {
            document.body.classList.remove('overflow-none');
        }
    }, [props.display])

    function disappearPopup(e : React.MouseEvent) {
        let target = e.target as HTMLElement;
        if(target.tagName === 'DIV')
            props.canBeDisplayed();
    }

    return(
        <div className={props.display ? 'backgroundPopup show' : 'backgroundPopup'} onClick={disappearPopup}>
            <a href="https://app.daily.dev/exploris" target="_blank" rel="noreferrer" id="dailyDevCard" className="gradient-border">
            <img src="https://api.daily.dev/devcards/v2/Iitz2PYtpGE1R3CxWrpS4.png?type=default&r=z31" alt="Exploris's Dev Card"/>
            </a>
        </div>
    );

}

export default DailyDevProfile;
import { useEffect, useState } from "react";
import Typewriter from 'typewriter-effect';
import { useTranslation } from "react-i18next";

function Chibi(props : any) {

    const { i18n } = useTranslation();

    const [state, setState] = useState({
        scale: '',
        dialog: "Merci de votre visite ヅ",
        typewriterKey: Date.now(), // Ajoute une clé unique pour forcer le remontage
      });

      
    useEffect(() => {
        if(i18n.language === "fr") {
            setState((prevState) => ({ ...prevState, dialog: 'Merci de votre visite ヅ' }));
          } else {
            setState((prevState) => ({ ...prevState, dialog: 'Thank you for your visit ヅ' }));
          }
          updateType();
    }, [i18n.language])

    useEffect(() => {
        setTimeout(() => {
            if(scrollY > props.deltaBegin && scrollY < props.deltaBegin + props.deltaEnd) {
                setState((prevState) => ({ ...prevState, scale: 'scale-up' }));
            }
            setState((prevState) => ({
              ...prevState,
              typewriterKey: Date.now()
            }));
        }, 2000);
        updateType();
      }, []);

      function updateType() {
        setState((prevState) => ({
          ...prevState,
          typewriterKey: Date.now()
        }));
      }

    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  


    return(
        <div id="explorisDraw">
            <div className={`exploris ${scrollY > props.deltaBegin && scrollY < props.deltaBegin + props.deltaEnd && state.scale === "scale-up" ? "visible" : "invisible" }`}>
                <img src={process.env.PUBLIC_URL + '/devexploris.webp'} alt="devexploris" height={200}/>
                <div className={`shape ${state.scale}`}>
                    {scrollY > props.deltaBegin && scrollY < props.deltaBegin + props.deltaEnd &&
                    <Typewriter
                    key={state.typewriterKey}
                    options={{ loop: false }}
                    onInit={(typewriter) => {
                        typewriter.deleteAll().typeString(state.dialog).start().start();
                    }}
                    />
            }
                </div>
            </div>
        </div>
    );

}

export default Chibi;
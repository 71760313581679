import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import axios from "axios";
import ChibiContact from "../components/ChibiContact";
import Typewriter from 'typewriter-effect';
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import Icons from "../components/Icons";

interface formContact {
  lastname : string,
  firstname : string,
  email : string,
  project : string,
  honey: string,
  action: string
}

function Contact() {

const newFormContact : formContact = {
  lastname : '',
  firstname : '',
  email : '',
  project : '',
  honey : '',
  action: 'contact'
}

const [formData, setFormData] = useState<formContact>(newFormContact);
const [count, setCount] = useState(0);
const [isCheck, setIsCheck] = useState(false);
const [typewriterKey, setTypewriterKey] = useState(Date.now())
const [status, setStatus] = useState('');

const { t } = useTranslation();


let dialogs = {
  start : i18n.language === "fr" ? "Envie de discuter de votre projet ? C'est ici" : "Want to discuss a project? Let's talk about it :)",
  maxChars : i18n.language === "fr" ? "Seulement 400 caractères pour votre projet" : "Only 400 characters for describe your project",
  success : i18n.language === "fr" ? "Votre message a été envoyé :)" : "Your message has been send :)",
  error : i18n.language === "fr" ? "Une erreur est survenue. Contactez moi sur LinkedIn" : "An error has occured, contact me on LinkedIn",
  missing : i18n.language === "fr" ? "Champ(s) manquant(s) :( Merci de tout compléter." : "missing field(s) :( Please complete all fields"
};

function updateType(status : string, error : boolean) {
  setDialog(status);
  setStatus(error ? 'red-alert' : 'green-alert');
  setTypewriterKey(Date.now());
  setTimeout(() => {
    setStatus('');
  }, 3000)
}

const [dialog, setDialog] = useState(dialogs.start);

async function handleSubmit(e: React.FormEvent) {
  e.preventDefault();
  if(formData.honey === '') {
    if (formData.project.length > 400) {
      updateType(dialogs.maxChars, true);
    } else {
      let data = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
          data.append(key, value);
      });
      await axios.post(process.env.PUBLIC_URL + "/scripts/contact.php", data, {
          headers: {
              "Content-Type": "application/json",
          }
      }).then(response => {
         if (response.data === true) {
          updateType(dialogs.success, false);
          setFormData(newFormContact);
          setIsCheck(false);
         } else {
          updateType(dialogs.error, true);
         }
      }).catch(error => {
          if (error.response && error.response.status === 500) { 
              updateType(dialogs.missing, true);
          } else {
              updateType(dialogs.error, true);
          }
      });
    }
  } else {
    updateType(dialogs.error, true);
  }
}

function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
  let { name, value } = e.target;
  setFormData((Prev) => ({
      ...Prev,
      [name]: value,
  }));
}

function handleCheck() {
  setIsCheck(isCheck ? false : true);
}

function handleChangeTextArea(e: React.ChangeEvent<HTMLTextAreaElement>) {
  let { name, value } = e.target;
  setFormData((Prev) => ({
      ...Prev,
      [name]: value,
  }));
  setCount(value.length);
}


    return(
          <motion.main
            className="main__container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
        >
          <ChibiContact dialog={dialog}/>
          <div className="limited flex-center flex-col">
            <Icons />
            <h2 className={`title`}><img src={process.env.PUBLIC_URL + "/icons/contact.webp"} alt="contact" /> Contact <img src={process.env.PUBLIC_URL + "/icons/contact.webp"} alt="contact" loading="lazy" /></h2>
            <form onSubmit={handleSubmit} className="form-contact flex-center flex-col">
              <div className={`title alert ${status}`}>
                <Typewriter
                  key={typewriterKey}
                  options={{ loop: false, cursor: "_" }}
                  onInit={(typewriter) => {
                    typewriter.deleteAll().typeString(dialog).start().pauseFor(1500).deleteAll().start();
                  }}
                />
              </div>
              <div className="input-box">
                <input type="text" name="lastname" required={true} onChange={handleChange} value={formData.lastname} />
                <label className="floating" htmlFor="lastname">{t('your')} {t("lastname")}</label>
              </div>
              <div className="input-box">
                <input type="text" name="firstname" required={true} onChange={handleChange} value={formData.firstname} />  
                <label className="floating" htmlFor="firstname">{t('your')} {t("firstname")}</label>
              </div>
              <div className="input-box">
                <input type="mail" name="email" required={true} onChange={handleChange} value={formData.email} />
                <label className="floating" htmlFor="email">{t('your')} {t("email")}</label>
              </div>
              <div className="input-box">
              <textarea name="project" required={true} maxLength={400} onChange={handleChangeTextArea} value={formData.project} />
              <label className="floating" htmlFor="project">{t('your')} {t("project")}</label>
              <span className="counter">{count} / 400</span>
              </div>
              <div className="none">
                <input type="text" name="honey" onChange={handleChange}/>
                <label className="floating" htmlFor="honey">Do not feed this field</label>
              </div>
              <div className="input-box">
                <input type="checkbox" name="tos" required={true} checked={isCheck} onChange={handleCheck} />
                <label className="check-label" htmlFor="tos">{t('agree')} <Link className="link underline" to={{pathname: '/legals-notices', hash: '#personnalData'}}>{t('tos')}</Link></label>
              </div>
              <button type="submit">
                  {t('send')}
              </button>
          </form>
        </div>
        </motion.main>
    );

}

export default Contact;
import { useEffect, useState } from "react";
import * as animationData from './scroll.json'
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import Lottie from "lottie-react";

function Hello() {

    const { t } = useTranslation();

      const [scrollClass, setScrollClass] = useState('appear');

      useEffect(() => {
        const handleScroll = () => {
          const scrollY = window.scrollY;
    
          const scrollThreshold = 70;
    
          if (scrollY > scrollThreshold) {
              setScrollClass('disappear');
            } else {
              setScrollClass('appear');
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); 
    

    return(
        <div className="landing">
            <p className="title left">
                <span className="move">👋</span> {t('welcome')}
            </p>
            <p className="left">
                {t('Im')} <span className="flicker">Julian</span> || <span className="flicker-alt">Exploris</span>
            </p>
            <p className="left">
                {t('status')} <a className="link underline" href="https://fr.wessling-group.com/fr/" target="_blank" rel="noreferrer">Wessling France S.A.S.</a>
            </p>
            <p className="left">
                {t('Im')} {i18n.language === "en" ? <img src={process.env.PUBLIC_URL + '/icons/french.webp'} width={20} alt="flag" /> : ''} {t('fullstackDev')} {i18n.language === "fr" ? <img src={process.env.PUBLIC_URL + '/icons/french.webp'} width={20} alt="flag"/> : ''} {t('since')} 2023.
            </p>
            <p className="left">
              {t('helpingPeople')}
            </p>
            <div className={`bottom ${scrollClass}`}>
                <Lottie id="scroll" autoPlay={true} loop={true} animationData={animationData} />
            </div>
        </div>
    );

}

export default Hello;
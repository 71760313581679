import { useTranslation } from "react-i18next";

interface elementType {
    img: string,
    text: string
}

function Joytistics() {

    const { t } = useTranslation();

    const elements : elementType[] = [
        {
            img: process.env.PUBLIC_URL + "/joytistics/coffee.webp",
            text: '1200 ' + t('coffee')
        },
        {
            img: process.env.PUBLIC_URL + "/joytistics/pen.webp",
            text: '3 ' + t('pen')
        },
        {
            img: process.env.PUBLIC_URL + "/joytistics/keyboard.webp",
            text: '80 000 ' + t('keyboard')
        },
        {
            img: process.env.PUBLIC_URL + "/joytistics/password.webp",
            text: '32 ' + t('password')
        },
        {
            img: process.env.PUBLIC_URL + "/joytistics/tabs.webp",
            text: '24 ' + t('tabs')
        }
    ]

    return(
        <div className="sideBar">
            {elements.map((element : elementType, index: number) => {
                return(
                    <div className="title side-info" key={index}>
                        <img src={element.img} alt={element.text} />
                        <span>{element.text}</span>
                    </div>
                )
            })}
            <div className="title side-info">
                <img src={process.env.PUBLIC_URL + "/joytistics/sound.webp"} />
                <div className="spotify">
                    <iframe src="https://open.spotify.com/embed/album/5F0wtZDesD8FWgFiggrMir?utm_source=generator" allowFullScreen={false} allow=" clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                </div>
            </div>
        </div>
    )

}

export default Joytistics;
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Icons from "../components/Icons";

function LegalsNotices() {

    const { t } = useTranslation();

    return(
          <motion.main
            className="main__container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 2 }}
        >
          <div className="limited flex-center flex-col">
          <Icons />
          <h2 className={`title`}><img src={process.env.PUBLIC_URL + "/icons/legals.webp"} alt="legals" /> {t('legalsNotices')} <img src={process.env.PUBLIC_URL + "/icons/legals.webp"} className="reverse" alt="legals" loading="lazy"/></h2>
          <hr className="break" />
            <h3 className="legalTitle">{t('hoisting')}</h3>
            <p className="legalP">
              {t('hoistingWebSite')} O2Switch.fr <br />
              {t('headOffice')} Chem. des Pardiaux, 63000 Clermont-Ferrand - France <br />
              {t('hostAuthor')}
            </p>
            <hr className="break" />
            <h3 className="legalTitle">{t('responsability')}</h3>
            <p className="legalP">
              {t('responsabilityFreeContent')}
            </p>
            <hr className="break" />
            <h3 className="legalTitle">{t('intellectualProperty')}</h3>
            <p className="legalP">
              {t('authorOwn')}
            </p>
            <hr className="break" />
            <h3 className="legalTitle">
              {t('contractualLimitations')}
            </h3>
            <p className="legalP">
              {t('javascriptUse')}
            </p>
            <hr className="break" />
            <h3 className="legalTitle">Cookies</h3>
            <p className="legalP">
              {t('cookieUse')}
            </p>
            <hr className="break" />
            <h3 className="legalTitle" id="personnalData">{t('personalData')}</h3>
            <p className="legalP">
              {t('personalDataIntro')}<br />
              {t('cnil')}<br />
              <br />
              {t('personnalDataUse')}<br />
              - {t('identity')} ({t('firstname')}, {t('lastname')})<br />
              - {t('email')}<br />
              <br />
              {t('expirationDate')}<br />
              {t('databaseUse')}
            </p>
          </div>
        </motion.main>
    );

}

export default LegalsNotices;
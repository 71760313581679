import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
.use(initReactI18next).init({
  lng: "fr",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
resources:{
    en: {
        translation: {
            leaveMessage: 'Leave me a message',
            toolbox: 'My toolbox',
            projects: 'My projects',
            profile: 'My LinkedIn profile',
            legalsNotices: 'A touch of french legals notices',
            welcome: 'Hi && Welcome !',
            Im: 'I\'m',
            status: 'Analyst - Programmer at',
            fullstackDev: 'Fullstack Developper',
            since: 'since',
            helpingPeople: 'I\'ve devoted my code to help people.',
            your: 'Your',
            lastname: 'lastname',
            firstname: 'firstname',
            email: 'email',
            project: 'project',
            send: 'Send',
            agree: 'I agree to be contacted again and to have read the',
            tos: 'T.O.S.',
            home: 'Home',
            aboutMe: 'About Me',
            firstTextAboutMe: 'Passionate about new technologies since a young age, I gravitated towards the field of computer science. However, I chose to delve into the magical world of biology during my studies. At the end of my academic journey, I naturally veered – driven by my educational background – towards the realm of laboratories.',
            secondTextAboutMe: 'In 2022, after a few years wearing a lab coat, I decided to turn my passion into a professional reality and embarked on a Bachelor\'s program in Web Application Development.',
            thirdTextAboutMe: 'This journey was fraught with challenges. Returning home after a day of work, learning to code, facing setbacks, and then starting over... At times, feeling alone in the face of seemingly insurmountable problems and persevering until they were resolved.',
            fourthTextAboutMe: 'But now, I can assist people in their work, design applications that save them time, and automate certain tasks. That\'s what I signed up for!',
            fifthTextAboutMe: 'In the end, I went from handling samples to handling code. I\'d say it\'s a rather logical evolution – after all, bugs are less sticky than chemical reagents! :D',
            projectEscapeOrbit: 'Inspired by FrontEnd Mentor.',
            projectCrepeEvent: 'For a Pancake Shop.',
            projectMercadona: 'My Final Exam with Studi',
            projectWorldExcape: 'Training Front Project',
            chibiSetProject: 'One click for context. Two click to visit.',
            hoisting: 'Hoisting',
            responsability: 'Responsability',
            intellectualProperty: 'Intellectual Property',
            contractualLimitations: 'Contractuals limitations on technical data',
            personalData: 'Personal Data Processing',
            hoistingWebSite: 'This site is hosted by',
            headOffice: 'Head Office :',
            hostAuthor: 'This host currently holds the personal information concerning the author of this site.',
            responsabilityFreeContent: 'The site editor provides free content. He permanently makes sure to put the means at his disposal to ensure the quality of his contents.',
            authorOwn: 'The author of the website owns the structure and images of the website.',
            javascriptUse: 'The site uses JavaScript. The website cannot be held responsible for any material damage related to its use. The user undertakes to navigate using recent equipment, free of viruses and with a latest generation browser. This site cannot bring you the best experience without javascript',
            cookieUse: 'The user is informed that no cookies are used on this website.',
            personalDataIntro: 'In France, personal data is protected by the law n° 78-87 of January 6, 1978, the law n° 2004-801 of August 6, 2004, the article L. 226-13 of the Penal Code and the European Directive of October 24, 1995. When using the site, the following information may be collected: the URL of the links through which the user accessed the site, the user\'s access provider, the user\'s Internet protocol (IP) address. In any case, the personal information relating to the user is collected only for the need of certain services proposed by the site. The user provides this information with full knowledge of the facts, in particular when he/she proceeds by himself/herself to their seizure.it is then specified to the user of the site the obligation or not to provide this information. In accordance with the provisions of articles 38 and following of the law 78-17 of January 6, 1978 relating to data processing, the files and freedoms, any user has a right of access, of correction and opposition to the personal data relating to it, by carrying out its request written and signed, accompanied by a copy of the title of identity with signature of the holder of the document, by specifying the address to which the answer must be sent. No personal information of the user of the site is published without the user\'s knowledge, exchanged, transferred, assigned or sold on any medium to third parties.',
            cnil: 'The information transmitted by the user is used solely for the purposes of responding to the contact form by the publisher. It is informed by a checkbox in the form.',
            personnalDataUse: 'The personal information requested is as follows:',
            identity: 'Identity:',
            expirationDate: 'They are archived for two years from the date of receipt.',
            databaseUse: 'The databases are protected by the provisions of the law of July 1, 1998 transposing the directive 96/9 of March 11, 1996 relating to the legal protection of databases.',
            toTop: 'Back to top',
            dailydev: 'My DailyDev profile',
            coffee: 'cups of coffee sacrificed',
            pen: 'pens lost (but never forgotten 😭)',
            keyboard: 'written lines of code',
            password: 'forgotten passwords',
            tabs: 'tabs open to resolve a bug'
        }
    },
    fr: {
        translation: {
            leaveMessage: 'Laissez moi un message',
            toolbox: 'Ma boite à outils',
            projects: 'Mes projets',
            profile: 'Mon profil LinkedIn',
            legalsNotices: 'Mentions légales',
            welcome: 'Bonjour && Bienvenue !',
            Im: 'Je suis',
            status: 'Analyste - Programmeur chez',
            fullstackDev: 'Développeur Fullstack',
            since: 'depuis',
            helpingPeople: 'Je consacre mon code à aider les autres.',
            your: 'Votre',
            lastname: 'Prénom',
            firstname: 'Nom',
            email: 'email',
            project: 'projet',
            send: 'Envoyer',
            agree: 'J\'accepte d\'être recontacté et ai pris connaissance des',
            tos: 'C.G.V.',
            home: 'Accueil',
            aboutMe: 'A propos de moi',
            firstTextAboutMe: 'Passionné par les nouvelles technologies depuis mon plus jeune âge, je me suis orienté vers le domaine de l\'informatique. Cependant, j\'ai choisi de me plonger dans le monde magique de la biologie pendant mes études. À la fin de mon parcours académique, je me suis naturellement orienté, porté par mon parcours scolaire, vers le monde des laboratoires.',
            secondTextAboutMe: 'En 2022, après quelques années en blouse blanche, je décide de faire de ma passion une réalité professionnelle et me lance dans un Bachelor en Développement d\'Applications Web.',
            thirdTextAboutMe: 'Ce voyage a été semé d\'embûches. Rentrer à la maison après une journée de travail, apprendre à coder, faire face à des échecs, puis recommencer... Parfois, se sentir seul face à des problèmes apparemment insurmontables et persévérer jusqu\'à ce qu\'ils soient résolus.',
            fourthTextAboutMe: 'Mais désormais, je peux assister les autres dans leur travail, concevoir des applications qui leur font gagner du temps et automatiser certaines tâches. C\'est exactement pour ça que je me suis tant engagé !',
            fifthTextAboutMe: 'Au final, je suis passé de la gestion des échantillons à la gestion du code. Je dirais que c\'est une évolution plutôt logique : après tout, les bugs sont moins collants que les réactifs chimiques ! ヅ',
            projectEscapeOrbit: 'Projet de FrontEnd Mentor',
            projectCrepeEvent: 'Pour un crépier évenementiel',
            projectMercadona: 'Mon examen final de Studi',
            projectWorldExcape: 'Projet d\'entrainement front',
            chibiSetProject: 'Un clic pour du contexte. Deux clics pour visiter.',
            hoisting: 'Hébergement',
            responsability: 'Responsabilité',
            intellectualProperty: 'Propriété Intellectuelle',
            contractualLimitations: 'Limitations contractuelles technique',
            personalData: 'Traitement des données personnelles',
            hoistingWebSite: 'Ce site est hébergé par',
            headOffice: 'Adresse :',
            hostAuthor: 'Cet hébergeur détient actuellement les informations personnelles concernant l\'auteur de ce site.',
            responsabilityFreeContent: 'L\'éditeur du site propose du contenu gratuit. Il veille en permanence à mettre les moyens à sa disposition pour assurer la qualité de son contenu.',
            authorOwn: 'L\'auteur du site est propriétaire de la structure et des images du site.',
            javascriptUse: 'Le site utilise JavaScript. Le site Internet ne pourra être tenu responsable de tout dommage matériel lié à son utilisation. L\'utilisateur s\'engage à naviguer en utilisant un matériel récent, exempt de virus et avec un navigateur de dernière génération. Ce site ne peut pas vous offrir la meilleure expérience sans javascript',
            cookieUse: 'L\'utilisateur est informé qu\'aucun cookie n\'est utilisé pour ce site.',
            personalDataIntro: 'En France, les données personnelles sont protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l\'article L. 226-13 du Code pénal et la Directive européenne du 24 octobre 1995. Lors de l\'utilisation du site, les informations suivantes peuvent être collectées : l\'URL des liens par l\'intermédiaire desquels l\'utilisateur a accédé au site, le fournisseur d\'accès de l\'utilisateur, l\'adresse de protocole Internet (IP) de l\'utilisateur. En tout état de cause, les informations personnelles relatives à l\'utilisateur ne sont collectées que pour le besoin de certains services proposés par le site. L\'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu\'il procède par lui-même à leur saisie. Il est alors précisé à l\'utilisateur du site l\'obligation ou non de fournir ces informations. Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l\'informatique, aux fichiers et aux libertés, tout utilisateur dispose d\'un droit d\'accès, de rectification et d\'opposition aux données personnelles le concernant. , en effectuant sa demande écrite et signée, accompagnée d\'une copie du titre d\'identité avec signature du titulaire de la pièce, en précisant l\'adresse à laquelle la réponse doit être envoyée. Aucune information personnelle de l\'utilisateur du site n\'est publiée à l\'insu de l\'utilisateur, échangée, transférée, cédée ou vendue sur un quelconque support à des tiers.',
            cnil: 'Les informations transmisent par l\'utilisateur sont utilisées uniquement à des fins de réponse au formulaire de contact par l\'éditeur. Il est informé par une case à cocher dans le formulaire.',
            personnalDataUse: 'Les informations personnelles demandées sont les suivantes :',
            identity: 'Identité :',
            expirationDate: 'Elles sont archivées pendant deux ans à compter de la date de réception.',
            databaseUse: 'Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.',
            toTop: 'Revenir en haut',
            dailydev: 'Mon profil DailyDev',
            coffee: 'tasses de cafés sacrifées',
            pen: 'stylos perdus (mais jamais oubliés 😭)',
            keyboard: 'lignes de codes écrites',
            password: 'mots de passe oubliés',
            tabs: 'onglets ouverts pour résoudre un bug'
        }
    },
}
});

export default i18n;
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function AboutMe(props : any) {

  const { t } = useTranslation();

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    
    return(
        <div className="flex-center flex-col" id="aboutme">
            <h2 className={`title lines rotate-out ${scrollY > props.delta ? 'rotate-in' : ''}`}><img src={process.env.PUBLIC_URL + "/icons/aboutme.webp"} alt={t('aboutMe')} loading="lazy" /> {t("aboutMe")} <img src={process.env.PUBLIC_URL + "/icons/aboutme.webp"} alt={t('aboutMe')} loading="lazy" /></h2>
            <p className={`fs-p slide-out-left ${scrollY > props.delta + 150 ? 'slide-in' : ''}`}>
            {t("firstTextAboutMe")}
            </p>
            <p className={`fs-p slide-out-right ${scrollY > props.delta + 350  ? 'slide-in' : ''}`}>
            {t("secondTextAboutMe")}
            </p>
            <p className={`fs-p slide-out-left ${scrollY > props.delta + 450  ? 'slide-in' : ''}`}>
            {t("thirdTextAboutMe")}
            </p>
            <p className={`fs-p slide-out-right ${scrollY > props.delta + 650  ? 'slide-in' : ''}`}>
            {t("fourthTextAboutMe")}
            </p>
            <p className={`fs-p slide-out-left ${scrollY > props.delta + 750  ? 'slide-in' : ''}`}>
            {t("fifthTextAboutMe")}
            </p>
        </div>
    );

}

export default AboutMe;
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function BackToTop(props: any) {

    const { t } = useTranslation();

    return(
        <Link to={{pathname: props.pathname, hash: '#top'}} className={`title link rotate-out ${props.scrollY > props.delta ? 'rotate-in' : ''}`}> <img src={process.env.PUBLIC_URL + '/icons/top.webp'} alt="top" />  {t('toTop')} <img src={process.env.PUBLIC_URL + '/icons/top.webp'} alt="top" /> </Link>
    );

}

export default BackToTop;


import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import LegalsNotices from './pages/LegalsNotices';
import Contact from './pages/Contact';
import ScrollToAnchor from './components/ScrollToAnchor';

function AppRouter() {

  const location = useLocation();

  return (
    <>
        <ScrollToAnchor />
        <Routes location={location} key={location.pathname}>
          <Route path="/" Component={Home} />
          <Route path="/legals-notices" Component={LegalsNotices} />
          <Route path="/contact" Component={Contact} />
        </Routes>
    </>
  );
}

export default AppRouter;
